@import 'settings';


.product-details {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $margin-medium;
    margin-top: $margin-large;
    background-color: $secondary-color-fade-light;
    padding: $margin-big $margin-medium;
   

    .additional-images {
        width: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $margin-bento;
        background-color: $secondary-color;
        outline: 1px solid $primary-color;

        img{
            display: block;
            width: 100%;
            object-fit: contain;
            border-radius: $margin-bento;
            box-shadow: $box-shadow-logo;
            border-bottom: 1px solid $primary-color;
        }
        &:hover{
            cursor: pointer;
        }

    }

    .main-image {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $margin-bento;
        border-radius: $margin-bento;
        box-shadow: $box-shadow-logo;
        position: relative;
        background-color: $secondary-color;
        outline: 1px solid $primary-color;
     

        .controls {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: $margin-bento;
        }

        img{
            display: block;
            width: 100%;
            object-fit: contain;
           
        }
        .transform-wrapper .hidden {
            visibility: hidden; /* Hide the default controls */
          }
    }

    .product-details-info{
        padding: $margin-big $margin-bento;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $margin-bento;
        position: relative;
        height: 100%;
        color: $secondary-color-black;
        outline: 1px solid $primary-color;

        h4{
          margin-bottom: 0;

        }
        
        p{
          font-size: $p-font-size;

         
         
        }
        span{
          display: inline;

          text-decoration: line-through;
          opacity: 0.8;
          font-size: $p-font-size-small;
            
        }
        .price{
          
          
            color: $secondary-color;
            background-color: $primary-color;
            display: inline;
            margin-right: $margin-tiny;
            

            
          
        }

        .description{
          text-align: center;
          font-size: $p-font-size-small;
        }
        .button-cta-cart-container{
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $secondary-color-black;    
            font-size: $p-font-size;
            border-radius: $margin-small;
            overflow: hidden;
            
           
           
            gap: $margin-small;
           
            button {
              width: 100%;     
              height: 100%; 
              background-color: $accent-color;
              cursor: pointer;
              padding:  $margin-small;
              
                
              &:hover {
                background-color: $secondary-color-black;
                color: $accent-color;
                font-weight: bold;
              }
            }
          }
          
       
    }
    
}


/* Hide the default toolbar */
.rppi-toolbar {
    display: none !important;
  }
  
  /* Hide the cursor icon */
  .rppi-cursor-icon {
    display: none !important;
  }
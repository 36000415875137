.cart-details {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.cart-details button {
  width: 100%;
  height: 100%;
  background-color: #FFD9E8;
  cursor: pointer;
  padding: 0.5rem;
}
.cart-details button:hover {
  background-color: #020202;
  color: #FFD9E8;
  font-weight: bold;
}
.cart-details .button-cta-cart-container {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #020202;
  font-size: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  gap: 0.5rem;
}
.cart-details .cart-items-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  border: 2px solid rgba(114, 114, 252, 0.3882352941);
}
.cart-details .cart-items-container img {
  display: block;
  width: 50px;
  height: 50px;
}
.cart-details .cart-items-container div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid rgba(114, 114, 252, 0.3882352941);
}
.cart-details .cart-items-container div p {
  font-size: 1rem;
}
.cart-details .cart-items-container div div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}/*# sourceMappingURL=cart.css.map */
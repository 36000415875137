@import 'settings';


.custom-wave {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }
  
  .custom-wave svg {
    position: relative;
    display: block;
    width: calc(187% + 1.3px);
    
  }
  
  .custom-wave .shape-fill {
    // fill: #5733FF;
  }
@import 'settings';
.client-logo-grid {
    width: 60%;
    margin: auto;
    margin-top: $margin-big;
    display: grid;
    /* Define the layout with 5 columns */
    grid-template-areas:
      "top-left top-center top-center top-center top-right"
      "center-left center center center center-right"
      "bottom-left bottom-center bottom-center bottom-center bottom-right";
    /* Define the height of rows and width of columns */
    grid-template-rows: auto 1fr auto;
    grid-template-columns: repeat(5, 1fr); /* 5 equal columns */
    gap: $margin-medium;
    justify-items: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    color:$secondary-color;
    background-color: rgba(0, 0, 0, 0.1);
    padding: $margin-medium;
    position: relative;

    
    .loader-clients{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 20%;
      justify-content: center;
      align-items: center;
      margin:auto;
      // background-color: $primary-color;
      border-radius: 25%;
    }
   
    
  }
  
  .text-container {
    grid-area: center; /* Place the text in the center grid cell */
    text-align: center; /* Center the text */
  }
  
  .client-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $margin-medium;
    overflow: hidden;
    border: 1px solid green;
    // height: 40px;
  }
  
  .logo {
    max-width: 100%; /* Ensure the image doesn't exceed the container width */
    height: auto; /* Maintain the aspect ratio */
  }
  
  /* Additional classes for placing logos in grid areas */
  .top-left {
    grid-area: top-top; /* Place logo in the top-left corner */
  }
  
  .top-center {
    grid-area: top-center; /* Place logo in the top-center */
  }
  
  .left-center {
    grid-area: left-center; /* Place logo in the left-center */
  }
  
  .right-center {
    grid-area: right-center; /* Place logo in the right-center */
  }
  
  .bottom-left {
    grid-area: bottom-left; /* Place logo in the bottom-left corner */
  }
  
  .bottom-center {
    grid-area: bottom-center; /* Place logo in the bottom-center */
  }
  
  .bottom-right {
    grid-area: bottom-right; /* Place logo in the bottom-right corner */
  }


  @media (max-width: 480px) {

    .loader-clients{
     
      min-width: 25%;
     
    }
    
  }
  
.place-holder {
  width: 100%;
  background-color: #eee;
  height: 2rem;
}

.blog-container {
  display: flex;
  width: 100%;
}
.blog-container .blog-page {
  flex: 4;
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 80%;
  margin: auto;
  margin-bottom: 1rem;
}
.blog-container .promo-space {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: rgba(114, 114, 252, 0.3882352941);
  padding: 0.25rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
}
.blog-container .promo-space .product-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  background-color: #eee;
  text-decoration: none;
}
.blog-container .promo-space .product-card img {
  width: 80%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.blog-container .promo-space .product-card span {
  font-size: 1rem;
}
.blog-container .promo-space .product-card .price-was {
  text-decoration: line-through;
  opacity: 0.5;
}
.blog-container .promo-space .product-card h4 {
  font-size: 0.8rem;
}
.blog-container .latest-blog {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.blog-container .latest-blog div span {
  display: block;
}
.blog-container .latest-blog .blog-content p {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  text-indent: 0.5rem;
}
.blog-container .latest-blog .blog-content img {
  width: 70%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  margin-left: 15%;
  border-radius: 1rem;
}
.blog-container .other-blogs {
  flex: 1;
  border-left: 1px solid #5733FF;
  padding-top: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.blog-container .other-blogs h4 {
  width: 90%;
  margin: auto;
}
.blog-container .other-blogs ul {
  list-style-type: none;
  padding: 0;
  width: 90%;
  margin: auto;
}
.blog-container .other-blogs li {
  cursor: pointer;
  padding: 0.5rem;
  border-bottom: 1px solid #ccc;
}
.blog-container .other-blogs li:hover {
  background-color: rgba(114, 114, 252, 0.3882352941);
}/*# sourceMappingURL=blog.css.map */
@import 'settings';

.motto-contactus{

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $margin-medium;
    text-align: center;
    margin: $margin-medium auto;
    background-color: $secondary-color-fade-light;
    color: $secondary-color;
    width: 80%;
    text-align: center;
    border-radius: $margin-small;
    position: relative;
   
    gap: 0;

    img {
      
        width: 25%;        
        object-fit: contain;
        z-index: 1;
        border-radius: $margin-medium;
      
       
    }
    >div{
        display: flex;
        flex-direction: column;
       
    }

    h4{     

        background-color: $primary-color;
        width: 50%;
        margin: $margin-small auto;
        padding: $margin-small;
        z-index: 2;
        @include merriweatherFontRegular;
        font-size: $p-font-size;

    }
    p{

        width: 70%;
        margin: auto;
        z-index: 2;
        color: $secondary-color-black;
        font-size: $h4-font-size;
        line-height: 1.5;
        
      
       

    }

}








  @import 'settings';
 

  .testimonies-wrapper {
    width: 90%;
    max-width: 1536px;
    margin-inline: auto;
    position: relative;
    height: 100px;
    margin-top: 5rem;
    overflow: hidden;
    height: 70vh;

    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1) 20%,
      rgba(0, 0, 0, 1) 80%,
      rgba(0, 0, 0, 0)
    );
  }

  @keyframes scrollLeft {
    to {
      left: -200px;
    }
  }
  
  .testimony-item {
    width: 25%;
    height: 80%;
   
   
    flex-shrink: 0;
   
   
    margin-right: $margin-big;
    color: $secondary-color;
    padding: $margin-tiny $margin-medium;
    border-bottom: 1px solid $primary-color;
    border-right: 1px solid $primary-color;
    border-left: 1px solid $primary-color;

    border-radius: $margin-small;

    position: absolute;
    // left: max(calc(20% * 5), 100%);
  


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-items: center;

    animation-name: scrollLeft;
    animation-duration: 30s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;   
    
    p{
      max-width: 90%;
      margin-bottom: $margin-small;
    }

    .quote-icon {
      font-size: $h2-font-size;
      color: $primary-color;
      margin: $margin-tiny auto;
      z-index: -1;

    }
    
    .left{
      position: absolute;
      left: 5%;
      top: 10%;
      transform: rotate(30deg);
    }
    .right{
      position: absolute;
      right: 10%;
      bottom: 10%;
      transform: rotate(30deg);
    }

    span{
      font-size: $p-font-size-small;
    }
    
  }
 

  @media (max-width: 480px) {

    .testimonies-container{
      height: 50dvh;
    }
    .testimonies-wrapper{
      height: 50dvh;
    }
    .testimony-item{
      min-width: 30%;
      

    }


    .loader-testimony{
     
      min-width: 20%;
      max-height: 50%;
   
    }
  
    
  }
  
@import 'settings';
h1{
    font-size: $h1-font-size;
}
h2{
    font-size: $h2-font-size;
}
h3{
    font-size: $h3-font-size;
}
p{
    font-size: $p-font-size;
}
@import 'settings';

.map-container{
    width: 80%;
    margin: auto;
  color: $secondary-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 2;
    position: relative;

    .loader-map{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 10%;
        justify-content: center;
        align-items: center;
        margin:auto;
    }

    p{
        color: $secondary-color;
        background-color: $secondary-color-black;

        span{
            display: block;
            font-size: $p-font-size-small;
        }
    }

  

}

@media (max-width: 480px) {

    .loader-map{
        min-width: 20%
    }
    
}
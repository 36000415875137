.footer {
  color: #5733FF;
  padding: 2rem 2rem;
}
.footer .footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left;
  gap: 1rem;
  margin: 0 auto;
}
.footer .footer-column {
  flex: 0 0 15%;
  margin-bottom: 1rem;
  text-align: left;
  padding: 0;
}
.footer .footer-column h4 {
  margin-bottom: 1rem;
  font-size: 1rem;
  text-align: left;
}
.footer .footer-column ul {
  list-style: none;
  padding: 0;
  font-size: 0.8rem;
  text-align: left;
  padding-left: 1rem;
}
.footer .footer-column ul li {
  margin-bottom: 0.5rem;
  text-align: left;
}
.footer .footer-column ul li a {
  color: #5733FF;
  text-decoration: none;
  text-align: left;
}
.footer .footer-column ul li a:hover {
  color: #020202;
  outline: 1px solid #FFFFFF;
}
.footer .footer-column .social-icons {
  display: flex;
  flex-direction: column;
}
.footer .footer-column .social-icons li {
  margin-left: 1rem;
}
.footer .footer-column .social-icons a {
  color: #5733FF;
  font-size: 20px;
}
.footer .footer-column .icon a {
  font-size: 20px;
}
.footer .footer-bottom {
  width: 80%;
  margin: auto;
  border-top: 2px solid #FFD9E8;
  text-align: center;
  margin-top: 1rem;
  color: #020202;
}
.footer .footer-bottom p {
  opacity: 0.9;
}/*# sourceMappingURL=footer.css.map */
.navigation-bar-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  background-color: rgba(114, 114, 252, 0.3882352941);
  padding: auto 0.5rem;
  transition: all 0.3s ease-in-out;
}
.navigation-bar-container .header {
  padding: 0.5rem 3rem;
  display: flex;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  width: 100%;
  z-index: 1000;
}
.navigation-bar-container .header .Web-logo {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 1rem;
  border-radius: 1rem;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 15px rgba(255, 217, 232, 0.5);
  width: 10%;
  /* Set the desired width */
  padding: 0.5rem;
}
.navigation-bar-container .header .header-nav {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.navigation-bar-container .header .header-nav > div {
  flex-grow: 1;
}
.navigation-bar-container .header .header-nav .navigation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-grow: 1;
  gap: 1rem;
  padding: 0;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links .nav-item-container {
  text-align: center;
  padding: 0.25rem;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links .nav-item-container .nav-item {
  text-decoration: none;
  color: #FFFFFF;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  transition: color 0.3s ease, outline 0.5s ease-in-out border-radius 0.5s ease;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links .nav-item-container .nav-item:hover {
  border: 1px solid #5733FF;
  background-color: #5733FF;
  border-radius: 0.25rem;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links .nav-item-container .nav-item.active {
  border: 1px solid rgba(83, 83, 246, 0.3882352941);
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  background-color: #020202;
  text-decoration: none;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links .search-navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links .search-navbar input {
  font-size: 0.8rem;
  padding: 0.5rem 0.25rem;
  text-indent: 0.25rem;
  width: 200px;
  border: 1px solid rgba(114, 114, 252, 0.3882352941);
  color: #FFFFFF;
  opacity: 80%;
}
.navigation-bar-container .header .header-nav .navigation-container .nav-links .search-navbar input:focus {
  outline: none;
  background-color: #020202;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  opacity: 100%;
}
.navigation-bar-container .header .header-nav .navigation-container .hamburger-menu {
  display: none;
  /* Hide by default on larger screens */
  cursor: pointer;
  padding: var(--margin-tiny);
}
@media (max-width: 600px) {
  .navigation-bar-container .header .header-nav .navigation-container {
    /* Show Navigation Links when the menu is open */
    /* Style for Navigation Links in the menu */
  }
  .navigation-bar-container .header .header-nav .navigation-container .navigation-container {
    position: relative;
  }
  .navigation-bar-container .header .header-nav .navigation-container .hamburger-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: black;
    height: 100%;
    width: 15vw;
    visibility: visible;
    color: #5733FF;
  }
  .navigation-bar-container .header .header-nav .navigation-container .hamburger-menu:hover {
    transform: scale(1.1);
    color: #FFD9E8;
    background-color: #020202;
    font-weight: bold;
  }
  .navigation-bar-container .header .header-nav .navigation-container .nav-links {
    opacity: 0;
    visibility: hidden;
  }
  .navigation-bar-container .header .header-nav .navigation-container .nav-links.show {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 70vh;
    margin: auto;
    opacity: 1;
    visibility: visible;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #020202;
    color: #020202;
    font-size: 3rem;
    border-radius: 0 0 0.5rem 0.5rem;
    opacity: 1;
    font-weight: 500;
    padding: 0.5rem;
  }
  .navigation-bar-container .header .header-nav .navigation-container .nav-links.show a {
    padding: 1rem;
    text-align: center;
    text-decoration: none;
    color: #020202;
  }
  .navigation-bar-container .header .header-nav .navigation-container .nav-links.show a:hover {
    border-radius: 0.25rem 0.5rem;
  }
  .navigation-bar-container .header .header-nav .navigation-container .nav-links.show a:active {
    background-color: #FFFFFF;
    color: #5733FF;
    border-radius: 0.25rem 0.5rem;
  }
  .navigation-bar-container .header .header-nav .navigation-container .search-navbar {
    color: #FFFFFF;
  }
  .navigation-bar-container .header .header-nav .navigation-container .search-navbar input {
    width: 40vw;
    border: 1px solid rgba(114, 114, 252, 0.3882352941);
    color: #FFFFFF;
    opacity: 80%;
  }
  .navigation-bar-container .header .header-nav .navigation-container .search-navbar input:focus {
    outline: none;
    background-color: #5733FF;
    border: 1px solid #5733FF;
    color: #FFFFFF;
    opacity: 100%;
  }
  .navigation-bar-container .header .header-nav .navigation-container .quick-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
    align-items: center;
    width: 80%;
  }
}
.navigation-bar-container .quick-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 15%;
}
.navigation-bar-container .quick-links #language-selector {
  padding: 0;
  cursor: pointer;
  font-size: 0.8rem;
}
.navigation-bar-container .quick-links .get-in-touch {
  font-size: 1rem;
}
.navigation-bar-container .quick-links .get-in-touch button {
  background-color: transparent;
  color: #FFD9E8;
  border: 1px solid #FFD9E8;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}
.navigation-bar-container .quick-links .get-in-touch button:hover {
  background-color: #FFD9E8;
  color: #5733FF;
  font-weight: normal;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* Adjust z-index as necessary */
  background-color: #FFFFFF;
  color: #020202;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: add a shadow for better visibility */
}
.sticky-nav .header .Web-logo {
  cursor: pointer;
  background-color: transparent;
  box-shadow: none;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .search-navbar {
  color: #020202;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .search-navbar input {
  font-size: 0.8rem;
  padding: 0.5rem 0.25rem;
  text-indent: 0.25rem;
  width: 200px;
  border: 1px solid #5733FF;
  color: #5733FF;
  opacity: 80%;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .search-navbar input:focus {
  outline: none;
  background-color: #020202;
  border: 1px solid #5733FF;
  color: #FFFFFF;
  opacity: 100%;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .nav-item-container {
  text-align: center;
  padding: 0.25rem;
  color: #020202;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .nav-item-container .nav-item {
  text-decoration: none;
  color: #020202;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  transition: color 0.3s ease, outline 0.5s ease-in-out border-radius 0.5s ease;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .nav-item-container .nav-item:hover {
  border: 1px solid #eee;
  background-color: #eee;
  border-radius: 0.25rem;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .nav-item-container .nav-item.active {
  border: 1px solid rgba(83, 83, 246, 0.3882352941);
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  background-color: #5733FF;
  color: #FFFFFF;
  text-decoration: none;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .quick-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .quick-links #language-selector {
  padding: 0;
  cursor: pointer;
  font-size: 0.8rem;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .quick-links .get-in-touch {
  font-size: 1rem;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .quick-links .get-in-touch button {
  background-color: transparent;
  color: #5733FF;
  border: 1px solid #5733FF;
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}
.sticky-nav .header .header-nav .navigation-container .nav-links .quick-links .get-in-touch button:hover {
  background-color: #5733FF;
  color: #FFFFFF;
  font-weight: normal;
}
.sticky-nav .header .header-nav .navigation-container .hamburger-menu {
  display: none;
  /* Hide by default on larger screens */
  cursor: pointer;
  padding: 0.5rem;
}/*# sourceMappingURL=navigation_bar.css.map */
.sub-container .featured-products-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  width: 100%;
  min-height: 60vh;
  padding-bottom: 4rem;
  position: relative;
  overflow: hidden;
}
.sub-container .featured-products-container h2 {
  color: #FFFFFF;
}
.sub-container .featured-products-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #5733FF;
  border-radius: 25%;
}
.sub-container .featured-products-container .featured-products {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 100%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem auto;
}
.sub-container .featured-products-container .featured-products > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}
.sub-container .featured-products-container .featured-products .featured-product-button {
  background-color: #FFD9E8;
  color: #020202;
  border-radius: 2rem;
  width: 5%;
}
.sub-container .featured-products-container .featured-products .featured-product-button:hover {
  background-color: #020202;
  color: #FFD9E8;
  cursor: pointer;
}
.sub-container .featured-products-container .featured-products .featured-product-info {
  background-color: #5733FF;
  color: #020202;
  height: 100%;
  border-bottom: 1px solid #FFFFFF;
  width: 30%;
}
.sub-container .featured-products-container .featured-products .featured-product-info p {
  color: yellow;
  text-transform: capitalize;
}
.sub-container .featured-products-container .featured-products .featured-product-info button {
  color: #5733FF;
}
.sub-container .featured-products-container .featured-products .featured-product-item {
  background-color: #FFD9E8;
  color: #020202;
  border-radius: 2rem;
  width: 30%;
  position: relative;
}
.sub-container .featured-products-container .featured-products .featured-product-item .featured-product-image {
  overflow: hidden;
  z-index: 2;
}
.sub-container .featured-products-container .featured-products .featured-product-item .featured-product-image img {
  height: 120px;
  -o-object-fit: contain;
     object-fit: contain;
}
.sub-container .featured-products-container .featured-products .featured-product-item .featured-product-detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #020202;
}
.sub-container .featured-products-container .featured-products .featured-product-item .featured-product-detail h4 {
  font-size: 1rem;
}
.sub-container .featured-products-container .featured-products .featured-product-item .featured-product-detail .featured-product-price {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sub-container .featured-products-container .featured-products .featured-product-item .featured-product-detail .featured-product-price .price-was {
  text-decoration: line-through;
  opacity: 0.5;
}
.sub-container .featured-products-container .featured-products .featured-product-item .featured-product-detail .featured-product-price .price-now {
  font-weight: bold;
}

@media (max-width: 480px) {
  .featured-products-container {
    width: 90%;
    margin: auto;
  }
  .featured-products {
    min-width: 90%;
  }
  .featured-product-info {
    max-width: 25%;
  }
  .loader {
    min-width: 20%;
  }
}/*# sourceMappingURL=featured-products.css.map */
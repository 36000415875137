.admin-content-form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  padding: 2rem auto;
}
.admin-content-form input, .admin-content-form textarea {
  width: 40%;
}
.admin-content-form table {
  width: 80%;
  margin: 2rem auto;
}
.admin-content-form thead {
  font-size: 0.8rem;
  font-weight: bold;
}
.admin-content-form thead th {
  font-size: 0.6rem;
}
.admin-content-form tbody {
  font-size: 0.8rem;
}
.admin-content-form tbody button {
  font-size: 0.6rem;
  background-color: #333;
  color: #eee;
  padding: 0.25rem;
}
.admin-content-form tbody button:hover {
  background-color: red;
}/*# sourceMappingURL=admin-staff.css.map */
.cube-about-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  background-color: #FFFFFF;
  position: relative;
}
.cube-about-container .displayed-item-details {
  position: absolute;
  top: 0;
  right: 5%;
  width: 40%;
  height: 30%;
  text-align: center;
  font-weight: bold;
  color: #5733FF;
  text-align: left;
}
.cube-about-container .displayed-item-details .image-container {
  width: 40%;
  height: 40%;
  position: relative;
  bottom: 0;
  left: 25%;
  z-index: 1;
}
.cube-about-container .displayed-item-details .image-container img {
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  object-fit: contain;
  z-index: 1;
  opacity: 0.8;
}
.cube-about-container .displayed-item-details h3, .cube-about-container .displayed-item-details p {
  z-index: 2;
}
.cube-about-container .displayed-item-details p {
  color: #020202;
  font-size: 0.8rem;
  line-height: 1.5;
}
.cube-about-container .cube-about-button-container {
  position: absolute;
  bottom: 15%;
  right: 5%;
  padding: 0.5rem;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cube-about-container .cube-about-button-container button {
  width: 100%;
  height: 100%;
  padding: 0;
  color: #020202;
  font-weight: no;
  background: linear-gradient(135deg, #5733FF, #FFD9E8);
}
.cube-about-container .cube-about-button-container button:hover {
  background: linear-gradient(135deg, #020202, #5733FF);
  font-weight: bold;
  color: #FFFFFF;
}

.intro-text-cube-about {
  color: #5733FF;
  font-size: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}

.cube-about {
  width: 400px;
  height: 400px;
  position: relative;
  right: 70%;
  transform-style: preserve-3d;
}
.cube-about .cube-about-item {
  position: absolute;
  width: 400px;
  height: 400px;
  border: 1px solid #5733FF;
  background-color: #eee;
  border-radius: 10%;
  border: 1px solid #5733FF;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  overflow: hidden;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border: 2px solid #5733FF;
}
.cube-about .cube-about-item .cube-about-content img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  transform: none !important;
}
.cube-about .cube-about-item .cube-about-content p {
  font-size: 0.6rem;
}

.cube-about .front {
  transform: translateZ(200px);
}

.cube-about .back {
  transform: rotateY(180deg) translateZ(200px);
}

.cube-about .right {
  transform: rotateY(-90deg) translateZ(200px);
}

.cube-about .left {
  transform: rotateY(90deg) translateZ(200px);
}

.cube-about .top {
  transform: rotateX(90deg) translateZ(200px);
}

.cube-about .bottom {
  transform: rotateX(-90deg) translateZ(200px);
}

@keyframes spin {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(-360deg);
  }
}
@media (max-width: 480px) {
  .cube-about-container {
    width: 95.2380952381vw;
    height: 66.6666666667vh;
    margin: auto;
  }
  .cube-about {
    position: relative;
    right: 20%;
  }
}/*# sourceMappingURL=cubeIntro2.css.map */
.staff-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%; /* You can use units like %, px, rem, etc. as needed */
  margin: auto;
  text-align: center;
  flex-wrap: wrap;
  position: relative;
}
.staff-container .loader-staff {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 25%;
}
.staff-container .staff-member {
  width: 20%;
  height: 22rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0.5rem;
  background-color: rgba(83, 83, 246, 0.3882352941);
  border-radius: 0.5rem;
  overflow: hidden;
}
.staff-container .staff-member img {
  max-height: 70%;
  width: 80%;
  margin-top: 0.5rem;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: #FFFFFF;
  border-radius: 0.5rem;
}
.staff-container .staff-member h4 {
  font-size: 1.12rem;
}
.staff-container .staff-member p {
  width: 100%;
  color: #FFFFFF;
  background-color: #5733FF;
  border-radius: 0.5rem;
}

@media (max-width: 480px) {
  .loader-staff {
    min-width: 20%;
  }
  .staff-member {
    min-width: 25%;
  }
  h4 {
    width: 100%;
  }
}/*# sourceMappingURL=company-staff.css.map */
.map-container {
  width: 80%;
  margin: auto;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2;
  position: relative;
}
.map-container .loader-map {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 10%;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.map-container p {
  color: #FFFFFF;
  background-color: #020202;
}
.map-container p span {
  display: block;
  font-size: 0.8rem;
}

@media (max-width: 480px) {
  .loader-map {
    min-width: 20%;
  }
}/*# sourceMappingURL=map.css.map */
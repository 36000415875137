.scroll-reveal-container {
  width: 100%;
  min-height: 100lvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  background-color: transparent;
  position: relative;
}

.landing-page-container {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}/*# sourceMappingURL=scroll_reveal.css.map */
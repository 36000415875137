@import 'settings';

.staff-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%; /* You can use units like %, px, rem, etc. as needed */
    margin: auto;
    text-align: center;
    flex-wrap: wrap;
    position: relative;

    .loader-staff{
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 15%;
        justify-content: center;
        align-items: center;
        margin:auto;
        // background-color: $primary-color;
        border-radius: 25%;
      }

    .staff-member{
        width: 20%;
        height: 22rem;
        padding: $margin-small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $margin-medium;
        margin: $margin-small;
        background-color: $primary-color-fade-dark;
        border-radius: $margin-small;
        overflow: hidden;

        
        img{
            
            max-height: 70%;
            width: 80%;

            margin-top: $margin-small;
            
            object-fit: contain;
            // outline: 1px solid $primary-color;
            background-color: $secondary-color;
            border-radius: $margin-small;
        }

        h4{
            font-size: calc($h4-font-size * 0.8);
        }

      
       

        p{
            width: 100%;
            color: $secondary-color;
            background-color: $primary-color;
            border-radius: $margin-small;
        }
    }
}

@media (max-width: 480px) {

    .loader-staff{

        min-width: 20%;
    }

    .staff-member{
        min-width: 25%;
    }

    h4{
      

        width: 100%;
        
    }
    
}
.policies-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  outline: 2px solid #5733FF;
  border-radius: 2rem;
  overflow: hidden;
  margin: 2rem auto;
}
.policies-container .policy-section ul, .policies-container .policy-section li, .policies-container .policy-section p {
  font-size: 0.8rem;
  line-height: 1.5;
  text-align: left;
  font-weight: normal;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}
.policies-container .policy-section p {
  margin-left: 2rem;
}
.policies-container h2 {
  margin-top: 1rem;
  width: 100%;
  background-color: #5733FF;
  color: #FFFFFF;
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto; /* For Safari and Safari on iOS */
  hyphens: auto;
}/*# sourceMappingURL=policies.css.map */
@import 'settings';


.cloudinary-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    flex-wrap: wrap;
    text-align: left;

    .cloudinary-uploader{
        // width: 80%;
        display: flex;
        
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;
        border: 1px solid green;
        width: 80%;
        margin: $margin-medium auto;
        padding: $margin-small;
        font-size: $p-font-size-small;

        table{
            width: 90%;
            margin: auto;

            th{
                font-size: $p-font-size-small;
            }

            button{
                font-size: $p-font-size-tiny;
            }
        }

    }
    
}

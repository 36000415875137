@import 'settings';


.payment-container {

    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem;
    background-color: $secondary-color;

    img{
        width: 50%;
        height: auto;
        object-fit: contain;
        &:hover{
            cursor: pointer;
        }
     
    }
}
  @import 'settings';

  .custom-container {
    width: 90%;
    margin: auto;
    //   height: 800vh;
    // background-color: #dfdfdf;




    section {
      width: 90%;
      height: 100vh;
      margin: auto;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: $margin-small;



      &.custom-hero {

        h1 {

          width: 100%;
          text-align: center;

          letter-spacing: -0.05em;
          line-height: 90%;
        }
      }

      &.custom-card {

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        // gap: $margin-small;

        &.custom-scroll {
          position: relative;

        }

        .custom-img {

          width: 40%;
          height: 80%;
          border: 1px solid black;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: $margin-big;
          padding: $margin-medium;
          background-color: $secondary-color-fade-dark;
          color: $secondary-color;

          h3 {
            z-index: 2;
          

          }

          .custom-pitch-title {

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            p {
              width: 80%;
              margin: auto;
            }

            .svg-back {
              z-index: 1;
            }
            
            // h3{
            //   word-break: break-word;
            //   overflow-wrap: break-word;
            //   -webkit-hyphens: auto; /* For Safari and Safari on iOS */
            //   hyphens: auto;
            // }
          }

          img {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }

      &.custom-footer {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        h1 {
          color: red;
          font-size: 2em;
          text-align: center;
          letter-spacing: -0.05em;
        }
      }
    }
  }

  @media (max-width: 480px) {

    .custom-container {

      section {
        height: 50vh;
        
      }

      .custom-pitch-title{
        h3{
          font-size: $h4-font-size;
          margin-left: 0;
        }
       
      }


    }
  }
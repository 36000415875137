.cube-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;
  background-color: #FFFFFF;
}

.intro-text-cube {
  color: #5733FF;
  font-size: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;
}

.cube {
  width: 200px;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  animation: spin 5s linear infinite;
}

.cube-image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  transform: none !important;
}

.cube div {
  position: absolute;
  width: 200px;
  height: 200px;
  border: 1px solid #5733FF;
  border-radius: 10%;
  border: 1px solid #5733FF;
}

.cube .front {
  transform: translateZ(100px);
}

.cube .back {
  transform: rotateY(180deg) translateZ(100px);
}

.cube .right {
  transform: rotateY(-90deg) translateZ(100px);
}

.cube .left {
  transform: rotateY(90deg) translateZ(100px);
}

.cube .top {
  transform: rotateX(90deg) translateZ(100px);
}

.cube .bottom {
  transform: rotateX(-90deg) translateZ(100px);
}

.cylinder {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  transform: translate(-50%, -50%) rotateX(90deg);
}

.circle {
  width: 100px;
  height: 100px;
  background-color: rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 0;
}

.side {
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 255, 0.5);
  position: absolute;
}

.side:nth-child(1) {
  transform: rotateY(0deg) translateZ(50px);
}

.side:nth-child(2) {
  transform: rotateY(90deg) translateZ(50px);
}

.side:nth-child(3) {
  transform: rotateY(180deg) translateZ(50px);
}

.side:nth-child(4) {
  transform: rotateY(-90deg) translateZ(50px);
}

@keyframes spin {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(-360deg);
  }
}/*# sourceMappingURL=CubeIntro.css.map */
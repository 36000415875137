@import 'settings';

.place-holder{
    width: 100%;
    background-color: $secondary-color-fade-light;
    height: $margin-big;

}
.blog-container{
    display: flex;
    width: 100%;
  


    .blog-page {
        flex: 4;
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 80%;
        margin: auto;
        margin-bottom: $margin-medium;
       
    }
    .promo-space{
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: $primary-color-fade-light;
        padding: $margin-bento;
        border-radius: $margin-small;
        gap: $margin-small;

        .product-card{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $margin-bento;
            background-color: $secondary-color-fade-light;
            text-decoration: none;
           
            img{
                width: 80%;
                height: auto;
                object-fit: contain;
            }

            span{
                font-size: $p-font-size;

              
            }
            .price-was{
                text-decoration: line-through;
                opacity: 0.5;
            }
            h4{
                font-size: $p-font-size-small;
            }
        }

       
    }
    
    
    
    .latest-blog {
        flex: 3;
        // width: 70%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        
        padding: $margin-small;
        margin-bottom: $margin-small;
    
        div{
            span{
                display:block;
            }
        }
    
        .blog-content{
    
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
    
            p{
                @include montserratFontRegular;
                font-size: $p-font-size-small;
                text-indent: $margin-small;
                // font-size: 8px;
                
            }
    
            img{
                width: 70%;
                height: auto;
                object-fit: contain;
                margin-left: 15%;
                border-radius: $margin-medium;
            }
    
        }
       
       
      
    }
    
    .other-blogs {
        flex: 1;
        border-left: 1px solid $primary-color;
         padding-top: $margin-extra-large;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    
        h4{
            width: 90%;
            margin: auto;
    
        }
    
        ul {
            list-style-type: none;
            padding: 0;
            width: 90%;
            margin: auto;
        }
        li {
            cursor: pointer;
            padding: $margin-small;
            border-bottom: 1px solid #ccc;
    
            &:hover {
                background-color: $primary-color-fade-light;
            }
        }
    }
}





.language {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
}
.language select {
  padding: 0.25rem;
  font-size: 0.6rem;
  font-family: "Montserrat", sans-serif;
}
.language select:hover, .language select:focus {
  outline: 1px solid #FFD9E8;
  cursor: pointer;
}
.language select option:focus, .language select option:hover {
  background-color: #FFD9E8;
  color: red;
}/*# sourceMappingURL=language_selector.css.map */
@import 'settings';

.policies-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 80%;
    margin: auto;

    outline: 2px solid $primary-color;
    border-radius: $margin-big;
    overflow: hidden;
    margin: $margin-big auto;


    .policy-section{

        ul, li, p{
            font-size: $p-font-size-small;
            line-height: 1.5;
            text-align: left;
            font-weight: normal;
            @include montserratFontRegular;
        }

        p{
            margin-left: $margin-big;
        }

    }

  

    h2{
        margin-top: $margin-medium;
        width: 100%;
        background-color: $primary-color;
        color:$secondary-color;
        word-break: break-word;
        overflow-wrap: break-word;
        -webkit-hyphens: auto; /* For Safari and Safari on iOS */
        hyphens: auto;
    }


}
.explore-more-container {
  position: absolute; /* Use absolute positioning to center relative to parent */
  top: 50%; /* Center vertically */
  left: 70%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Translate to achieve perfect centering */
  z-index: 1001; /* Adjust z-index as needed */
  opacity: 1; /* Ensure opacity is set as needed */
}
.explore-more-container button {
  font-size: 1.4rem;
  padding: 0.5rem 3rem;
}/*# sourceMappingURL=explore_more.css.map */
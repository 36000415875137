.product-details {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  background-color: #eee;
  padding: 2rem 1rem;
}
.product-details .additional-images {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  background-color: #FFFFFF;
  outline: 1px solid #5733FF;
}
.product-details .additional-images img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  border-radius: 0.25rem;
  box-shadow: 5px 5px 15px rgba(255, 217, 232, 0.5);
  border-bottom: 1px solid #5733FF;
}
.product-details .additional-images:hover {
  cursor: pointer;
}
.product-details .main-image {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  border-radius: 0.25rem;
  box-shadow: 5px 5px 15px rgba(255, 217, 232, 0.5);
  position: relative;
  background-color: #FFFFFF;
  outline: 1px solid #5733FF;
}
.product-details .main-image .controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}
.product-details .main-image img {
  display: block;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.product-details .main-image .transform-wrapper .hidden {
  visibility: hidden; /* Hide the default controls */
}
.product-details .product-details-info {
  padding: 2rem 0.25rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  position: relative;
  height: 100%;
  color: #020202;
  outline: 1px solid #5733FF;
}
.product-details .product-details-info h4 {
  margin-bottom: 0;
}
.product-details .product-details-info p {
  font-size: 1rem;
}
.product-details .product-details-info span {
  display: inline;
  text-decoration: line-through;
  opacity: 0.8;
  font-size: 0.8rem;
}
.product-details .product-details-info .price {
  color: #FFFFFF;
  background-color: #5733FF;
  display: inline;
  margin-right: 0.125rem;
}
.product-details .product-details-info .description {
  text-align: center;
  font-size: 0.8rem;
}
.product-details .product-details-info .button-cta-cart-container {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #020202;
  font-size: 1rem;
  border-radius: 0.5rem;
  overflow: hidden;
  gap: 0.5rem;
}
.product-details .product-details-info .button-cta-cart-container button {
  width: 100%;
  height: 100%;
  background-color: #FFD9E8;
  cursor: pointer;
  padding: 0.5rem;
}
.product-details .product-details-info .button-cta-cart-container button:hover {
  background-color: #020202;
  color: #FFD9E8;
  font-weight: bold;
}

/* Hide the default toolbar */
.rppi-toolbar {
  display: none !important;
}

/* Hide the cursor icon */
.rppi-cursor-icon {
  display: none !important;
}/*# sourceMappingURL=product_details.css.map */
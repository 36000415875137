h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
}/*# sourceMappingURL=App.css.map */
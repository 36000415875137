@import 'settings';

.cta-about {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: $margin-small;
    margin: auto;
    

    .cta-about-sub{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $margin-small;
        p{
            
                width: 70%;
                line-height: 1.5;
            
        }
        img{
            max-width: 30%;
           height: auto;
            object-fit: contain;
        }
    }

    button{
        background-color: $primary-color;
        color: $accent-color;
    }

   
}

@media (max-width: 480px) {

  
    
}
.main-container {
  border: none;
}
.main-container .sub-container .motto-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%; /* You can use units like %, px, rem, etc. as needed */
  margin: auto;
  text-align: center;
  color: #FFFFFF;
  padding-top: 2rem;
}
.main-container .sub-container .motto-container h1 {
  margin: auto;
}
.main-container .sub-container p {
  font-size: 1rem;
}

.wave {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}/*# sourceMappingURL=home.css.map */
@import 'settings';
.main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: $primary-color;
    
    color: $secondary-color;

    h1{
    //     word-break: break-word;
    // overflow-wrap: break-word;
    // -webkit-hyphens: auto; /* For Safari and Safari on iOS */
    // hyphens: auto;
    // max-width: 100%;
    }

    .wave-about {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        line-height: 0;
    }
    .motto-container {
        position: relative;
    }

}


@media (max-width: 480px) {

    .motto-container{
        
        h1{
            font-size: calc($h2-font-size/1.2);
            
            min-width: 90%;
            margin: auto;
        }
    }
    
}
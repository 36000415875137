@import 'settings';

.admin-login-page,
.admin-dashboard{
    background-color: $primary-color;
    color: $secondary-color;
    font-family: $montserrat-font-family;
    min-height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $margin-small;
    align-items: center;
    gap: $margin-small;
    position: relative;
    button{
        background-color: $accent-color;
        color: $secondary-color-black;
        border-radius: $margin-bento;
        font-size: $p-font-size;
        font-weight: bold;
        cursor: pointer;
        padding: $margin-small;
        
    }
    button:hover{
        background-color: $secondary-color-black;
        color: $accent-color;
        border: 1px solid $secondary-color-black;
    }
   
    
}

.admin-login-page{
    button{
        background-color: $accent-color;
        color: $secondary-color-black;
        border-radius: $margin-bento;
        font-size: $p-font-size;
        font-weight: bold;
        cursor: pointer;
        padding: $margin-small;
        width: 50%;
        margin: auto;
        
    }
    button:hover{
        background-color: $secondary-color-black;
        color: $accent-color;
        border: 1px solid $secondary-color-black;
    }
    input{
        font-size: $p-font-size-small;
        padding: $margin-medium;
        border-radius: $margin-bento;
        
        font-family: $montserrat-font-family;
        margin-right: $margin-small;
    }
   
}

.logo-container{
    width: 100px;
    height: 100px;
    position: absolute;
    top: $margin-bento;
    left: $margin-big;
    background-color: $secondary-color;
    border-radius: $margin-medium;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: contain;
}
}
.admin-dashboard{
    h2{
        font-size: $h3-font-size;
        
    }
    h3{
        font-size: $h4-font-size;
       

    }

    h3, h4{
        color: $secondary-color;
        background-color: $primary-color;
        margin-top: $margin-big;
    }
    .admin-button-logout{
        position: absolute;
        top: $margin-medium;
        right: $margin-large;
        background-color: transparent;
        color: $secondary-color;
        border: 1px solid $secondary-color-black;
        font-weight: normal;  
    
    }
    .admin-button-logout:hover{
        background-color: $secondary-color;
        color: $primary-color;
        border: 1px solid $secondary-color-black;
        font-weight: bold;  
    }

}

.admin-welcome{
    color: $secondary-color-black;
    font-size: $h3-font-size;
}


.admin-items{
    background-color: $secondary-color;
    color: $secondary-color-black;
    border-radius: $margin-bento;
    padding: $margin-small;
    display: flex;
    flex-direction: column;
    gap: $margin-small;
    align-items: left;
    margin: auto $margin-medium;
    position: relative;
    min-height: 50vh;
    min-width: 50vw;
    table{
        margin-top: $margin-medium;
        border: 2px solid $secondary-color-black;
    }
    thead{
        background-color: $secondary-color-black;
        color: $secondary-color;
        font-weight: bold;
        font-size: $p-font-size;
    }
    tbody p{
        font-size: $p-font-size-small;
    }
    tbody img{
        width: 40px;
        height: 40px;
        object-fit: contain;
    }
    input, textarea{
        font-size: $p-font-size-small;
        padding: $margin-medium;
        border-radius: $margin-bento;
        
        font-family: $montserrat-font-family;
        margin-right: $margin-small;
    }
    textarea {
        border: 1px solid $secondary-color-black;
        height: 100px; /* Set a specific height for the textarea */
        resize: vertical; /* Allow vertical resizing of the textarea */
    }
  
    // form{
    //     display: flex;
    //     // flex-direction: row;
    //     flex-wrap: wrap;
    //     justify-content: baseline;
    //     gap: $margin-small;
    //     align-items: left;
    //     margin: auto $margin-medium;
    //     label{
    //         display: block;
    //     }
        
    //     input, button{
    //         height: 30%;
    //         margin: auto $margin-small;
    //     }
    //     // input, textarea{
    //     //     width: 40%;
    //     // }
    //     td{
    //         button{
    //             width: 100%;
    //             text-align: center;
    //         }
    //     }
        
    
    // }
 

    .admin-buttons-container{
        
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: $margin-small;
       
        button{
            // display: block;
            background-color: $primary-color-fade-dark;
            color: $secondary-color;
            border: 1px solid $secondary-color-black;
            padding: $margin-medium $margin-large;
            font-weight: normal;
            
            &:hover{
                background-color: $secondary-color-black;
                color: $primary-color;
            
            
        }
       
        }
        button.active{            
            background-color: $secondary-color-black;
            color:$accent-color            
        }


      
    }
   
 
}










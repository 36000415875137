html {
  font-size: 16px;
}

@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  html {
    font-size: 12px;
  }
}
body {
  margin: 0;
  padding: 1rem;
  background-color: #5733FF;
  color: #FFFFFF;
  overflow-x: hidden;
}
body p, body label, body input {
  font-size: 1rem;
}
body p, body button, body span, body label, body input, body textarea {
  font-family: "Merriweather Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
body h1, body h2, body h3, body h4 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.2;
}
body li {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}
body h1 {
  font-size: 4rem;
  color: #FFFFFF;
  font-weight: bold;
  text-transform: uppercase;
  width: 80%;
  margin: auto;
  margin: 3rem auto;
  text-align: center;
}
body h2 {
  font-size: 3rem;
  width: 80%;
  margin: auto;
  margin-bottom: 3rem;
  text-align: center;
  text-transform: uppercase;
}
body h3 {
  font-size: 2rem;
  width: 80%;
  margin: auto;
  margin-bottom: 1rem;
  text-align: center;
}
body h4 {
  font-size: 1.4rem;
  width: 80%;
  margin: auto;
  margin-bottom: 0.5rem;
  text-align: center;
}
body button {
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: #FFD9E8;
  border: 1px solid #FFFFFF;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}
body button:hover {
  background-color: #020202;
  color: #FFD9E8;
  font-weight: bold;
}
body .main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #5733FF;
  margin: 0.5rem auto;
  padding: 0;
  gap: 0;
}
body .main-container .sub-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #020202;
  margin: auto;
  padding-top: 0;
}
body .main-container .sub-container input {
  font-size: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 3rem;
  border-radius: 0.25rem;
  text-align: left;
  text-indent: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid rgba(114, 114, 252, 0.3882352941);
}
body .main-container .sub-container .error-message {
  color: #FFA500;
  margin-bottom: 0.5rem;
}
body button {
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  background-color: #FFD9E8;
  cursor: pointer;
}
body button:hover {
  background-color: #020202;
  color: #FFD9E8;
  font-weight: bold;
}/*# sourceMappingURL=index.css.map */
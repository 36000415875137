@import 'settings';

.products-intro-page{
    width: 100vw;
    // min-height: 200vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    flex-wrap: wrap;
    box-sizing: border-box; /* Ensure that padding and border are included in the width */
}

.products-intro-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: $margin-bento;
    padding-bottom: $margin-bento;
    box-sizing: border-box; /* Ensure that padding and border are included in the width */
}

.products-intro-display{
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box; /* Ensure that padding and border are included in the width */
  }
  
  .product {
    width: calc(25% - (2 * $margin-bento)); /* Adjust width to accommodate margins */
    // height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: $margin-bento;
    background-color: $primary-color-fade-light;
    flex-grow: 1; /* Allow child elements to grow and fill the available space */
    box-sizing: border-box; /* Ensure that padding and border are included in the width */
   
   h4{
       text-align: center;
       font-size: $h4-font-size;
       
   }
   p{
    display: block;
       text-align: center;
       
   }
   img {
    display: block;
    max-width: 50%;
    max-height: 50%;
    object-fit: contain; /* Maintain aspect ratio and fit within container */
    
}
}
   

.button-cta-main{
    margin-top: $margin-bento;
    margin-bottom: $margin-bento;
    width: 100%;
    height: 10%;
    background-color: $accent-color;
    color: $secondary-color-black;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    padding: $margin-small;
}

.button-cta-main:hover{
    background-color: $secondary-color-black;
    color: $accent-color;
}
  
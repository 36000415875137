.pitch-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 2rem;
  gap: 2rem;
}
.pitch-container .pitch {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin: auto;
  padding: 2rem;
  margin-bottom: 1rem;
  gap: 1rem;
  position: relative;
  text-align: center;
  height: 250px;
}
.pitch-container .pitch > div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}
.pitch-container .pitch > div img {
  width: 60%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  outline: 1px solid #FFFFFF;
  border-radius: 2rem;
}
.pitch-container .pitch > div p {
  text-transform: capitalize;
}
.pitch-container .pitch .pitch-title {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}/*# sourceMappingURL=about-pitch.css.map */
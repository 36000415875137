.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #5733FF;
  color: #FFFFFF;
}
.main-container .wave-about {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}
.main-container .motto-container {
  position: relative;
}

@media (max-width: 480px) {
  .motto-container h1 {
    font-size: 2.5rem;
    min-width: 90%;
    margin: auto;
  }
}/*# sourceMappingURL=about.css.map */
@import 'settings';
opoi;
// Default root font size
html {
    font-size: 16px;
  }
  
  // Medium screens
  @media (max-width: 768px) {
    html {
      font-size: 14px;
    }
  }
  
  // Small screens
  @media (max-width: 480px) {
    html {
      font-size: 12px;
    }
  }

body {

    margin: 0;
    padding: $margin-medium;
    background-color: $primary-color;
    color: $secondary-color;
    overflow-x: hidden;
   

    p, label, input {
        font-size: $p-font-size;
       
    }

    p, button, span, label, input,textarea{
        @include merriweatherFontRegular;
    }

    h1, h2, h3, h4{

        @include montserratFontBold;

    }

    li{
        @include montserratFontRegular;
    }

    h1 {
        font-size: $h1-font-size;      
        color: $secondary-color;
        font-weight: bold;
        text-transform: uppercase;
        width: 80%;
        margin: auto;
        margin: $margin-large auto;
        text-align: center;
               
    }

    h2 {
        font-size: $h2-font-size;
        width: 80%;
        margin: auto;
        margin-bottom: $margin-large;
        text-align: center;
        text-transform: uppercase;
    }

    h3 {
        font-size: $h3-font-size;
        width: 80%;
        margin: auto;
        margin-bottom: $margin-medium;
        text-align: center;
        
      
    }

    h4 {
        font-size: $h4-font-size;
        width: 80%;
        margin: auto;
        margin-bottom: $margin-small;
        text-align: center;
        

       
    }

    button { 
        padding: $margin-small;
        border-radius: $margin-bento;
        font-size: $p-font-size;
        background-color: $accent-color;
        border: 1px solid $secondary-color;

        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;

        cursor: pointer;
       

        &:hover {
            background-color: $secondary-color-black;
            color: $accent-color;
            font-weight: bold;
        }
    }



    .main-container {
        width: 100%;
        
        height: 100%;
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $primary-color;
      
        margin: $margin-small auto;
        padding: 0;
        // border: 1px solid red;

        // box-sizing: border-box; // Ensure that padding and border are included in the width
        //  margin: $margin-extra-large auto;
        //  padding-top: $margin-extra-large;
        gap: 0;
        // background: linear-gradient(to bottom, $primary-color, $secondary-color);
      
        



        .sub-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
                     
            color: $secondary-color-black;
            margin: auto;
            padding-top: 0;
            // border: 1px solid red;





      

            input {
                font-size: $p-font-size;
                padding-top: $margin-small;
                padding-bottom: $margin-small;
                padding-right: $margin-large;
                border-radius: $margin-bento;
                text-align: left;
                text-indent: $margin-small;

                margin-right: $margin-small;
                border: 1px solid $primary-color-fade-light;
            }

            .error-message {
                color: $error-color;
                margin-bottom: $margin-small;
            }



        }

    }
    button { 
        padding: $margin-small $margin-medium;
        border-radius: $margin-bento;
        font-size: $p-font-size;
        background-color: $accent-color;
        cursor: pointer;

        &:hover {
            background-color: $secondary-color-black;
            color: $accent-color;
            font-weight: bold;
        }
    }


}
@import 'settings';

.language {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: $margin-bento;
  // border: 1px solid $secondary-color;

  select{
    padding: $margin-bento;
    font-size: $p-font-size-tiny;
    font-family: $montserrat-font-family;
    

    &:hover,
  &:focus {
    // background-color: $primary-color;
    outline: 1px solid $accent-color;
    // color: $secondary-color;
    cursor: pointer;
  }

    option {
      &:focus,
      &:hover {
          background-color: $accent-color;
          color: red;
          
      }
  }
  }

}


@import 'settings';

.footer {
    // width: 100%;
    // background-color: $primary-color;
    color: $primary-color;
    padding: $margin-big $margin-big;

  

    .footer-container {
        display: flex;
        flex-wrap: wrap;
        // justify-content: space-around;
        justify-content: space-between;
        text-align: left;
        gap:$margin-medium;
       
        // max-width: 1200px;
        margin: 0 auto;

      
    }

    .footer-column {
        flex: 0 0 15%;
      
        margin-bottom: $margin-medium;
        text-align: left;
        
       
        padding: 0;;
      
        

        h4 {
            // font-size: 18px;
            margin-bottom: $margin-medium;
            font-size: $p-font-size;
            text-align: left;
           
            
        }

        ul {
            list-style: none;
            padding: 0;
            font-size: $p-font-size-small;
            text-align: left;
            padding-left: calc($margin-medium);

            li {
                margin-bottom: $margin-small;
                text-align: left;

                a {
                    color: $primary-color;
                    text-decoration: none;
                    text-align: left;
                    

                    &:hover {
                        // text-decoration: underline;
                        color: $secondary-color-black;
                        outline: 1px solid $secondary-color;
                    }
                }
            }
        }


        .social-icons {
            display: flex;
            flex-direction: column;
            

            li {
                margin-left: $margin-medium;
            }

            a {
                color: $primary-color;
                font-size: 20px;
            }
        }

        .icon {
            a{
                font-size: 20px;
            }
        }


    }

    .footer-bottom {
        width: 80%;
        margin: auto;
        border-top: 2px solid $accent-color;
        text-align: center;
        margin-top: $margin-medium;
        color: $secondary-color-black;
       p{
        opacity: 0.9;
       }
      }
}
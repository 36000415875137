@import 'settings';

.navigation-bar-container {
  width:100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  background-color: $primary-color-fade-light;
  padding: auto $margin-small;
  transition: all 0.3s ease-in-out;
 

  // border: 2px solid green;

  .header {


    padding: $margin-small $margin-large;
    display: flex;
    /* justify-content: center; */
    justify-content: flex-start;
    align-items: center;
    gap: $margin-large;
    width: 100%;

    z-index: 1000;
 
    // border: 2px solid white;




    .Web-logo {


      cursor: pointer;

      display: flex;
      justify-content: center;
      align-items: center;

      overflow: hidden;

      margin-right: $margin-medium;
      // border: 2px solid coral;
      border-radius: $margin-medium;
      background-color: $secondary-color;
      // padding-right: $margin-small;
      // padding-left: $margin-small;
      box-shadow: $box-shadow-logo;

      // width: 10%;

      width: 10%;
      /* Set the desired width */
      // height: 50px;
    
      padding: $margin-small;

   }

    .header-nav {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      overflow: hidden;
   

      >div {

        flex-grow: 1;
      }

      .navigation-container {

       
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        // border: 2px solid red;
        flex-grow: 1;

        gap: $margin-medium;
        padding: 0;


        .nav-links {
          display: flex;
          flex-direction: row;
          width: 100%;
          // justify-content: space-between;
          justify-content: space-around;
          align-items: center;
        
          gap: $margin-medium;
        

          .nav-item-container {
            text-align: center;
            padding: $margin-bento;

            .nav-item {
              text-decoration: none;
              color: $secondary-color;
              font-size: $p-font-size;
              padding: $margin-bento $margin-small;
              transition: color 0.3s ease, outline 0.5s ease-in-out border-radius 0.5s ease;

              &:hover {
                // color: $secondary-color-black;
                // outline: 1px solid $secondary-color;
                // border-top:  1px solid $primary-color;
                // border-bottom:  1px solid $primary-color;
                border:  1px solid $primary-color;
                background-color: $primary-color;
                border-radius: $margin-bento;
                

              }

              &.active {
                border: 1px solid $primary-color-fade-dark;
                border-radius: 5px;
                padding: $margin-bento $margin-small;
                // color: $primary-color;
                background-color: $secondary-color-black;
                text-decoration: none;
              }
            }
          }

          .search-navbar {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: $margin-bento;



            input {
              font-size: $p-font-size-small;
              padding: $margin-small $margin-bento;
              text-indent: $margin-bento;

              width: 200px;
              border: 1px solid $primary-color-fade-light;
              color: $secondary-color;
              opacity: 80%;

              &:focus {
                outline: none;
                background-color: $secondary-color-black;
                border: 1px solid $secondary-color;
                color: $secondary-color;
                opacity: 100%;
              }

            }


          }

        }

        .hamburger-menu {
          display: none;
          /* Hide by default on larger screens */
          cursor: pointer;
          padding: var(--margin-tiny);
        }

        @media (max-width: 600px) {

          .navigation-container{
            position: relative;
          }

          .hamburger-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $h3-font-size;
            position: absolute;
            top: 0;
            right: 0;

            z-index: 999;
            background-color: black;
            height: 100%;
            width: 15vw;
            visibility: visible;
            color: $primary-color;
          }

          .hamburger-menu:hover {
            transform: scale(1.1);
            color: $accent-color;
            background-color: $secondary-color-black;
            font-weight: bold;
          }

          .nav-links {
            opacity: 0;
            visibility: hidden;
          }

          /* Show Navigation Links when the menu is open */
          .nav-links.show {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 70vh;
            margin: auto;

            opacity: 1;

            visibility: visible;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: $margin-medium;
           
            
            background-color: $secondary-color-black;
            color: $secondary-color-black;

            
            font-size: $h2-font-size;
            border-radius: 0 0 $margin-small $margin-small;
            opacity: 1;
            font-weight: 500;
            padding: $margin-small;
          }

          /* Style for Navigation Links in the menu */
          .nav-links.show a {
            padding: $margin-medium;
            text-align: center;
            text-decoration: none;
            color: $secondary-color-black;
          }

          .nav-links.show a:hover {
            // background-color: $secondary-color;
            // color: $primary-color;
            border-radius: $margin-bento $margin-small;
          }
          .nav-links.show a:active {
            background-color: $secondary-color;
            color: $primary-color;
            border-radius: $margin-bento $margin-small;
          }

          .search-navbar {
            color: $secondary-color;



            input {
            
              width: 40vw;
              border: 1px solid $primary-color-fade-light;
              color: $secondary-color;
              opacity: 80%;

              &:focus {
                outline: none;
                background-color: $primary-color;
                border: 1px solid $primary-color;
                color: $secondary-color;
                opacity: 100%;
              }

            }


          }

          .quick-links {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: $margin-small;
            align-items: center;
            width: 80%;
            // border: 1px solid magenta;
                
        
          }
        }


      }

    }

  }

  .quick-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15%;
    // border: 1px solid magenta;

    #language-selector {


      padding: 0;
      cursor: pointer;
      font-size: $p-font-size-small;
    }

    .get-in-touch {
      font-size: $p-font-size;

      button {
        background-color: transparent;
        color: $accent-color;
        border: 1px solid $accent-color;
        padding: $margin-bento $margin-small;
        font-size: $p-font-size-small;

        &:hover {
          background-color: $accent-color;
          color: $primary-color;
          font-weight: normal;
        }
      }

    }


  }
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* Adjust z-index as necessary */
  background-color: $secondary-color;
  color: $secondary-color-black;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: add a shadow for better visibility */

  .header {

    .Web-logo {


      cursor: pointer;


      background-color: transparent;
      // outline: 1px solid $primary-color;

      box-shadow: none;


    }

    .header-nav {
      .navigation-container {

        .nav-links {
        

          .search-navbar {
            color: $secondary-color-black;


            input {
              font-size: $p-font-size-small;
              padding: $margin-small $margin-bento;
              text-indent: $margin-bento;

              width: 200px;
              border: 1px solid $primary-color;
              color: $primary-color;
              opacity: 80%;

            

              &:focus {
                outline: none;
                background-color: $secondary-color-black;
                border: 1px solid $primary-color;
                color: $secondary-color;
                opacity: 100%;
              }

            }


          }

        
      
          .nav-item-container {
            text-align: center;
            padding: $margin-bento;
            color: $secondary-color-black;
      
            .nav-item {
              text-decoration: none;
              color: $secondary-color-black;
              font-size: $p-font-size;
              padding: $margin-bento $margin-small;
              transition: color 0.3s ease, outline 0.5s ease-in-out border-radius 0.5s ease;
      
              &:hover {
      
                border:  1px solid $secondary-color-fade-light;
                background-color: $secondary-color-fade-light;
                border-radius: $margin-bento;
      
              }
      
              &.active {
                border: 1px solid $primary-color-fade-dark;
                border-radius: 5px;
                padding: $margin-bento $margin-small;
                // color: $primary-color;
                background-color: $primary-color;
                color: $secondary-color;
                text-decoration: none;
              }
            }
          }
          .quick-links {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // border: 1px solid magenta;
        
            #language-selector {
        
        
        
              padding: 0;
              cursor: pointer;
              font-size: $p-font-size-small;
            }
        
            .get-in-touch {
              font-size: $p-font-size;
        
              button {
                background-color: transparent;
                color: $primary-color;
                border: 1px solid $primary-color;
                padding: $margin-bento $margin-small;
                font-size: $p-font-size-small;
        
                &:hover {
                  background-color: $primary-color;
                  color: $secondary-color;
                  font-weight: normal;
                }
              }
        
            }
        
        
          }

        }

        .hamburger-menu {
          display: none;
          /* Hide by default on larger screens */
          cursor: pointer;
          padding: $margin-small;
        }


      }




    }



   



  }

 
}
@import 'settings';


.sub-container{
  .motto-products{
    h2{
      color: $secondary-color;
      text-transform: uppercase;
    }

  }

  .search-container{
 
    width: 50%;
    margin: auto;
    margin-bottom: $margin-medium;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: $margin-small;
    position: relative;

    .loader{
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 15%;
      justify-content: center;
      align-items: center;
      margin:auto;
      background-color: $primary-color;
      border-radius: 25%;
    }
  
    .search-input{
      width: 100%;
      font-size: $p-font-size-small;
      padding-top: $margin-small;
      padding-bottom: $margin-small;
      padding-right: $margin-large;
      border-radius: $margin-bento;
      text-align: left;
      text-indent: $margin-small;
      
      font-family: $montserrat-font-family;
      margin-right: $margin-small;
      border: 1px solid $primary-color-fade-light;
    }
    .search-button{
      // display: block;
      background-color: $primary-color-fade-light;
      color: $secondary-color;
      border: 1px solid $primary-color-fade-light;
      padding-top: $margin-small;
      padding-bottom: $margin-small;
      text-align: center;  
     
      margin-right: $margin-small;
      
      &:hover{
          background-color: $secondary-color-black;
          color: $accent-color;
          cursor: pointer;
      
    }
    }
  }

  
  
    .motto-products{


     
    
      
    }
  
    
    .products-display {
      width: 90%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      border: 2px solid $primary-color-fade-light;
      margin: auto;
      background-color: $secondary-color-fade-light;
      color: $secondary-color-black;
      padding: $margin-large $margin-big;
      gap: $margin-small;
      // border: 2px solid red;
    
     
  
      a {
        text-decoration: none;
        color: inherit;
        display: block;
        width: calc(30% - 0.5rem); // Adjust width to accommodate margins
  
        &:hover {
          text-decoration: none;
          color: inherit;
        }
      }
     
     
    }
    
    .product-item {
      width: 100%;
      height: 18rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: $margin-bento;
      background-color: $secondary-color;
      flex-grow: 1; // Allow child elements to grow and fill the available space
      border-radius: $margin-small;
    
      
      h4 {
        text-align: center;
        font-size: $h4-font-size;
        
      }
      
      p {
        display: block;
        text-align: center;
        font-size: $p-font-size-small;
      }

      h4, p{
        word-break: break-word;
        overflow-wrap: break-word;
        -webkit-hyphens: auto; /* For Safari and Safari on iOS */
        hyphens: auto;
      }
      
      img {
        display: block;
        max-width: 50%;
        max-height: 50%;
        object-fit: contain; // Maintain aspect ratio and fit within container
        transition: transform 0.3s ease-in-out;
      }
  
      &:hover img {
        transform: scale(1.3); /* Adjust the scale factor as needed */
        cursor: pointer;
        text-decoration: none;
  

      
      }
      .price-container{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
       

        >p:nth-child(2){
          text-decoration: line-through;
          opacity: 0.8;
        }
      }
    }
  
    .button-cta-products{
      width: 20%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 10vh;
      color: $secondary-color-black;    
      font-size: $p-font-size;
      overflow: hidden;
      border-radius: $margin-small;
      padding: $margin-small;
     
      button {
        width: 100%;     
        height: 100%; 
        background-color: $accent-color;
        cursor: pointer;
          
        &:hover {
          background-color: $secondary-color-black;
          color: $accent-color;
          font-weight: bold;
        }
      }
    }

}

@media (max-width: 480px) {
  .button-cta-products{
    min-width: 50%;
    margin: auto;
    max-height: 5vh;
  
}
}

  

  
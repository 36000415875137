@import "settings";

form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
 
    gap: $margin-medium;
    outline: 1px solid $primary-color;
    border-radius: $margin-small;
    position: relative;
   

    .form-input-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      
        width: 40%;
        padding: $margin-medium;
        
      
        
        input{
            width: 100%;
            padding:$margin-small;
            margin-bottom: $margin-medium;
            border: 1px solid #ccc;
            border-radius:$margin-bento;
            box-sizing: border-box;
           
           

            &[type="text"],
            &[type="tel"],
            &[type="email"] {
              width: 100%;
            }

            &:focus{
              outline: 2px solid $primary-color;
              background-color: $secondary-color-black;
              color: $secondary-color;
              font-size: $p-font-size-small;
            }
            &::placeholder{
              opacity: 0.7;
              font-size: $p-font-size-small;
             
            }
          }

    }
  
     .form-textarea-container{
        @extend .form-input-container;

        textarea{
            width: 100%;
            padding: $margin-small;
            margin-bottom: $margin-medium;
            border: 1px solid #ccc;
            border-radius: $margin-bento;
            box-sizing: border-box;
            height: 150px;
      resize: vertical;

      &:focus{
        outline: 2px solid $primary-color;
        background-color: $secondary-color-black;
        color: $secondary-color;
        font-size: $p-font-size-small;
      }
      &::placeholder{
        opacity: 0.7;
        font-size: $p-font-size-small;
       
      }
        }
        button {
            width: 100%;
            background-color: $primary-color;
            color: $accent-color;
            padding: $margin-medium $margin-big;
            margin: $margin-small 0;
            border: none;
            border-radius: $margin-bento;
            cursor: pointer;
           
        
            &:hover {
             background-color: $secondary-color-black;
             outline: 2px solid $primary-color;
            }
          }
     }

     .form-status{
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $secondary-color-fade-light;
      color: $primary-color;
      
      transform: translate(-50%, -50%);
      width: 50%;
      height: 20%;
      margin: auto;
      text-align: center;
      // color: yellow;
      font-size: $h3-font-size;
      // background-color: $secondary-color-black;
      padding: $margin-big $margin-large;
      // border: 2px solid $primary-color;
      border-radius: $margin-small;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      outline: 1px solid $primary-color;
     
     }

     .form-processing{
      @extend .form-status;
      width: 10%;

     }
    
   
   
  }


.quick-contact-point{
    margin-top: $margin-small;
    width: 100%;
    height: 5rem;
    // background-color: $secondary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $margin-extra-large;
    color: $primary-color;
    font-size: $h3-font-size;
    outline: 3px solid $secondary-color-black;
    border-radius: $margin-small;
    margin-top: $margin-big;
    margin-bottom: $margin-extra-large;
    padding: auto $margin-medium;
    >div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $margin-small;
    }
    p{
        display: inline-block;
        font-size: $h3-font-size;
       
      
      
    }
}


@media (max-width: 480px) {

  form{
    gap:$margin-small;;
  }
  .form-input-container{
    gap: $margin-small;
    min-width: 90%;
    margin: auto;

    input{
      margin-bottom: 0;
    }

   
  }

  .form-status{
 
    min-width: 20%;
  }

  .quick-contact-point{
    gap: $margin-small;
    
}

  
}
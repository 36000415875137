.products-intro-page {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5733FF;
  flex-wrap: wrap;
  box-sizing: border-box; /* Ensure that padding and border are included in the width */
}

.products-intro-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  box-sizing: border-box; /* Ensure that padding and border are included in the width */
}

.products-intro-display {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box; /* Ensure that padding and border are included in the width */
}

.product {
  width: calc(25% - 0.5rem); /* Adjust width to accommodate margins */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.25rem;
  background-color: rgba(114, 114, 252, 0.3882352941);
  flex-grow: 1; /* Allow child elements to grow and fill the available space */
  box-sizing: border-box; /* Ensure that padding and border are included in the width */
}
.product h4 {
  text-align: center;
  font-size: 1.4rem;
}
.product p {
  display: block;
  text-align: center;
}
.product img {
  display: block;
  max-width: 50%;
  max-height: 50%;
  -o-object-fit: contain;
     object-fit: contain; /* Maintain aspect ratio and fit within container */
}

.button-cta-main {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 100%;
  height: 10%;
  background-color: #FFD9E8;
  color: #020202;
  border-radius: 5px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  padding: 0.5rem;
}

.button-cta-main:hover {
  background-color: #020202;
  color: #FFD9E8;
}/*# sourceMappingURL=products.css.map */
.checkout-field {
  margin-bottom: 1rem;
  max-width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}
.checkout-field label {
  display: inline-block;
  margin-right: 1rem;
}
.checkout-field > div {
  border-radius: 0.25rem;
  padding: 4rem 1rem;
}
.checkout-field > div:nth-child(-n+2) {
  border: 1px solid #FFFFFF;
}
.checkout-field .order-complete-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #020202;
  color: #5733FF;
  border-radius: 0.25rem;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  border: 1px solid #FFD9E8;
}/*# sourceMappingURL=check_out.css.map */
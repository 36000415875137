@import 'settings';

.cart-item {
    position: fixed;
    top: $margin-small;
    right: $margin-small;
    display: flex;
    width: 4rem;
    height: 4rem;
    justify-content:center;
    align-items: center;
    color: $accent-color;
    background-color: $secondary-color-black;
    // padding: $margin-medium;
    border-radius: $margin-bento;
    margin-bottom: $margin-small;
    font-size: $p-font-size;
    z-index: 2001;

    &:hover{
        cursor: pointer;
        font-size: calc($p-font-size * 1.25);
    }


}
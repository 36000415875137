@import 'settings';

$cube-about-container-width: 100vw;
$cube-about-container-height: 100vh;
$cube-about-size: 400px;
$cube-about-translate-size: 200px;
$cube-about-border-color: $primary-color;
// $cube-about-background-color: $secondary-color-fade;
$cube-about-border-radius: 10%;
$cube-about-border: 1px solid $cube-about-border-color;

$cylinder-size: $cube-about-translate-size; // Diameter of the circle
$cylinder-height: $cube-about-translate-size; // Height of the cylinder
$cylinder-color: rgba(255, 0, 0, 0.5); // Semi-transparent red color for the circle
$side-color: rgba(0, 0, 255, 0.5); // Semi-transparent blue color for the sides

.cube-about-container {
  width: $cube-about-container-width;
  height: $cube-about-container-height;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px; // Perspective for 3D effect
  background-color: $secondary-color;
  position: relative;
 
 
 

  .displayed-item-details{
    position: absolute;
    top: 0;
    right: 5%;
    width: 40%;
    height: 30%;
   
    text-align: center;
    font-weight: bold;
    color: $primary-color;
    text-align: left;

    // h3{
    //   margin-bottom: ;
    // }

    .image-container{
      width: 40%;
      height: 40%;
      position: relative;
      bottom: 0;
      left: 25%;
      z-index: 1;
      img{
        width: 100%;
        object-fit: contain;
        // position: absolute;
        // bottom: 0;
        // left: 25%;   
       
        object-fit: contain;
        z-index: 1;
        opacity: 0.8;
    }

    }
   

   h3, p{
  
      z-index: 2;
   }
   
     p{
       
        color: $secondary-color-black;
        font-size: $p-font-size-small;
        line-height: 1.5;

    }
  }

  .cube-about-button-container{
    position: absolute;
    bottom: 15%;
    right: 5%;
    // transform: translateX(-50%);
    padding: $margin-small;
    width: 70px;
    height: 70px;
    border-radius: 50%;
   
    overflow: hidden;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
   

    button{
       width: 100%;
       height: 100%;
       padding: 0;
       color: $secondary-color-black;
       font-weight: no;
       background: linear-gradient(135deg, #5733FF, #FFD9E8);

       &:hover{
        background: linear-gradient(135deg, #020202, #5733FF);
        font-weight: bold;
        color: $secondary-color;
        

       }

    }
  }
}
.intro-text-cube-about{
  color: $primary-color;
  font-size: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;

}
.cube-about {
  width: $cube-about-size; // $cube-about-size;
  height: $cube-about-size; // $cube-about-size;
  position: relative;
  right: 70%;
  transform-style: preserve-3d;
  // animation: spin 5s linear infinite; // Animation for continuous rotation

  .cube-about-item{
    position: absolute;
    width: $cube-about-size;
    height: $cube-about-size;
    border: 1px solid $cube-about-border-color;
    background-color: $secondary-color-fade-light;
    border-radius: $cube-about-border-radius;
    border: $cube-about-border;
    display: grid;

    grid-template-columns: 1fr 1fr;
    align-items: center;

   
   
    overflow: hidden;
  
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    border: 2px solid $primary-color;

    .cube-about-content{
        // width: 50%;
        // border: 1px solid $secondary-color-black;

        img{
            width: 100%;
            height: 100%;
            object-fit: contain; // Ensure the image fills the entire div
            transform: none !important; // Override any inherited transformations
        }

        p{
          font-size: $p-font-size-tiny;
        }
    }
  }
}



.cube-about .front {
  transform: translateZ($cube-about-translate-size);
 
}

.cube-about .back {
  transform: rotateY(180deg) translateZ($cube-about-translate-size);

}

.cube-about .right {
  transform: rotateY(-90deg) translateZ($cube-about-translate-size);
 
}

.cube-about .left {
  transform: rotateY(90deg) translateZ($cube-about-translate-size);
 
}

.cube-about .top {
  transform: rotateX(90deg) translateZ($cube-about-translate-size);
  
}

.cube-about .bottom {
  transform: rotateX(-90deg) translateZ($cube-about-translate-size);
  
}




@keyframes spin {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(-360deg);
  }
}

@media (max-width:480px) {

  .cube-about-container {
    width: calc($cube-about-container-width/1.05);
    height: calc($cube-about-container-height/1.5);
    margin: auto;
  }

  .cube-about{
    position: relative;
  right: 20%;
  }
  
  
}

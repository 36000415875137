@import 'settings';


.main-container{
  border: none;
  .sub-container {

    .motto-container {
        // padding-top: $margin-small;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
    
        width: 90%; /* You can use units like %, px, rem, etc. as needed */
        margin: auto;
        text-align: center;
        color: $secondary-color;
        padding-top: $margin-big;
      
    
        // >div:first-child{
        //   display: flex;
         
    
        //   h1{
        //     margin-top: 0;
        //   };
        // }
        h1{
          // width: 80%;
          margin: auto;
        }
     
       
        
      }
    
      p{
        font-size: $p-font-size;
    
      }
    
        
    }

}


.wave{
  position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  // border: 2px solid red;
}



@media (max-width: 480px) {

  .motto-container{
    // margin-top: 200px;
    // min-height: 60vh;
  }
  
}


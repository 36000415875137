.motto-contactus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  margin: 1rem auto;
  background-color: #eee;
  color: #FFFFFF;
  width: 80%;
  text-align: center;
  border-radius: 0.5rem;
  position: relative;
  gap: 0;
}
.motto-contactus img {
  width: 25%;
  -o-object-fit: contain;
     object-fit: contain;
  z-index: 1;
  border-radius: 1rem;
}
.motto-contactus > div {
  display: flex;
  flex-direction: column;
}
.motto-contactus h4 {
  background-color: #5733FF;
  width: 50%;
  margin: 0.5rem auto;
  padding: 0.5rem;
  z-index: 2;
  font-family: "Merriweather Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
}
.motto-contactus p {
  width: 70%;
  margin: auto;
  z-index: 2;
  color: #020202;
  font-size: 1.4rem;
  line-height: 1.5;
}/*# sourceMappingURL=contact-us.css.map */
@import 'settings';


.checkout-field{
   margin-bottom: $margin-medium;
   max-width: 80%;
   margin: auto;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   gap: $margin-medium;
   label{
    display: inline-block;
    margin-right: $margin-medium;
   }

   >div{
      border-radius: $margin-bento;
      padding: $margin-extra-large $margin-medium;

   }
   >div:nth-child(-n+2){
      border: 1px solid $secondary-color;
   }
   

   .order-complete-message{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $secondary-color-black;
      color: $primary-color;
      border-radius: $margin-bento;
      padding: $margin-medium;
      font-size: $p-font-size;
      font-weight: bold;
      border: 1px solid $accent-color;
   }
}
.payment-container {
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: #FFFFFF;
}
.payment-container img {
  width: 50%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.payment-container img:hover {
  cursor: pointer;
}/*# sourceMappingURL=payment-telebirr.css.map */
@import 'settings';
.landing-page {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    flex-wrap: wrap;
    gap: $margin-bento;
    position: relative;

    .mouse-container{
      width: 50%;
      height: 50%;
      position: absolute;
      top: 25%;
      right: 25%;
      // transform: translate(-50%, -50%);
      
    }

    .enter-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        z-index: 1001;
        position: absolute;
        top: 20%;
        left: 48.5%;
        transform: translate(-50%, -50%);
        background-color: transparent;
        // color: $secondary-color-black;
        border: none;
        font-weight: bold;

        span{
          // animation: buttonAnimation 1s forwards;
          animation: buttonAnimation 2s infinite;

        }
       

        &:hover{
            // background-color: transparent;
            color: $secondary-color-black;
            outline: 1px solid $secondary-color-black;
            // transform: scale(1.2);
            font-size: bold;

            span{
              animation: none;
            }
        }
        // transform: 'translate(-50%, -50%) rotate(-90deg)', // Rotate text
        


    }
  }

 

  .landing-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: bold;
    color: $secondary-color;
    z-index: 1000;
  }
  
  .box {
    width: 12rem;
    height: 12rem;
    margin: $margin-small;
    background-color: $primary-color-fade-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    color: darkblue;
    font-size: 1.5rem;
    font-weight: bold;

    
  }
  .even{
    border-radius: $margin-small;
    background-color: $primary-color-fade-light;
    box-shadow: $box-shadow-blue-strong; 

  }


  
  .text {
    font-size: 1.5rem;
    margin-top: 1.5rem;
  }


  @keyframes buttonAnimation {
    0% {
     
      color: $secondary-color-black; /* Start with white background */
    }
    50% {
     
      color: $secondary-color; /* Change background color to red */
    }
    100% {
    
      color: $secondary-color-black; /* Return to white background */
    }
  }
  
form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  outline: 1px solid #5733FF;
  border-radius: 0.5rem;
  position: relative;
}
form .form-input-container, form .form-textarea-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  padding: 1rem;
}
form .form-input-container input, form .form-textarea-container input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
}
form .form-input-container input[type=text], form .form-textarea-container input[type=text], form .form-input-container input[type=tel], form .form-textarea-container input[type=tel], form .form-input-container input[type=email], form .form-textarea-container input[type=email] {
  width: 100%;
}
form .form-input-container input:focus, form .form-textarea-container input:focus {
  outline: 2px solid #5733FF;
  background-color: #020202;
  color: #FFFFFF;
  font-size: 0.8rem;
}
form .form-input-container input::-moz-placeholder, form .form-textarea-container input::-moz-placeholder {
  opacity: 0.7;
  font-size: 0.8rem;
}
form .form-input-container input::placeholder, form .form-textarea-container input::placeholder {
  opacity: 0.7;
  font-size: 0.8rem;
}
form .form-textarea-container textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  box-sizing: border-box;
  height: 150px;
  resize: vertical;
}
form .form-textarea-container textarea:focus {
  outline: 2px solid #5733FF;
  background-color: #020202;
  color: #FFFFFF;
  font-size: 0.8rem;
}
form .form-textarea-container textarea::-moz-placeholder {
  opacity: 0.7;
  font-size: 0.8rem;
}
form .form-textarea-container textarea::placeholder {
  opacity: 0.7;
  font-size: 0.8rem;
}
form .form-textarea-container button {
  width: 100%;
  background-color: #5733FF;
  color: #FFD9E8;
  padding: 1rem 2rem;
  margin: 0.5rem 0;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}
form .form-textarea-container button:hover {
  background-color: #020202;
  outline: 2px solid #5733FF;
}
form .form-status, form .form-processing {
  position: absolute;
  top: 50%;
  left: 50%;
  background-color: #eee;
  color: #5733FF;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 20%;
  margin: auto;
  text-align: center;
  font-size: 2rem;
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: 1px solid #5733FF;
}
form .form-processing {
  width: 10%;
}

.quick-contact-point {
  margin-top: 0.5rem;
  width: 100%;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  color: #5733FF;
  font-size: 2rem;
  outline: 3px solid #020202;
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding: auto 1rem;
}
.quick-contact-point > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.quick-contact-point p {
  display: inline-block;
  font-size: 2rem;
}

@media (max-width: 480px) {
  form {
    gap: 0.5rem;
  }
  .form-input-container, form .form-textarea-container {
    gap: 0.5rem;
    min-width: 90%;
    margin: auto;
  }
  .form-input-container input, form .form-textarea-container input {
    margin-bottom: 0;
  }
  .form-status, form .form-processing {
    min-width: 20%;
  }
  .quick-contact-point {
    gap: 0.5rem;
  }
}/*# sourceMappingURL=contact-point.css.map */
@import 'settings';


.sub-container {

.featured-products-container{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

   
    margin: auto;
    text-align: center;
   
    width: 100%;
    min-height: 60vh;
    padding-bottom: $margin-extra-large;
    position: relative;
    overflow: hidden;

    h2{
        color: $secondary-color;
    }

    .loader{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        width: 10%;
        justify-content: center;
        align-items: center;
        margin:auto;
        background-color: $primary-color;
        border-radius: 25%;
      }
    
  
    

    .featured-products{

        display: flex;
        flex-direction: row;
        width: 80%;
        height: 100%;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: $margin-big;
       
        padding: $margin-big auto;
       

        >div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: $margin-small;
            padding: $margin-small;
          
           
          
           
        }

        .featured-product-button{

            background-color: $accent-color;
            color: $secondary-color-black;
            border-radius: $margin-big;
            width: 5%;

            &:hover{
                background-color: $secondary-color-black;
                color: $accent-color;
                cursor: pointer;
            }

        }



        .featured-product-info{
           
            background-color: $primary-color;
            color: $secondary-color-black;
            height: 100%;
          
            border-bottom: 1px solid $secondary-color;
            width: 30%;

            p{
                color: yellow;
                text-transform: capitalize;
            }
           

            button{
                color: $primary-color;
            }
        }

        .featured-product-item{
            
            background-color: $accent-color;
            color: $secondary-color-black;
            border-radius: $margin-big;
            width: 30%;
            position: relative;
            .featured-product-image{

                overflow: hidden;
                z-index: 2;
               
                img{
                    
                    height: 120px;
                    // width: 80px;

                    object-fit: contain;
                    
                }
            }

           .featured-product-detail{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-top: 1px solid $secondary-color-black;
                // gap: $margin-small;
                h4{
                    font-size: $p-font-size;
                }

                .featured-product-price{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    .price-was{
                        text-decoration: line-through;
                        opacity: 0.5;
                    }
                    .price-now{
                        font-weight: bold;
        
                    }
                }

               
            }

           
        }
    }
  }

}

@media (max-width: 480px) {

    .featured-products-container{
      
        width: 90%;
        margin: auto;
    }

    .featured-products{
        min-width: 90%;
        // border: 1px solid red;
        // gap: $margin-small;
      
    }
    // .featured-product-item , 
    .featured-product-info{
        max-width: 25%;
    }

    .loader{
        
        min-width: 20%;
      

        
      }

    
   
}
.cart-item {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  display: flex;
  width: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  color: #FFD9E8;
  background-color: #020202;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  z-index: 2001;
}
.cart-item:hover {
  cursor: pointer;
  font-size: 1.25rem;
}/*# sourceMappingURL=cart_item.css.map */
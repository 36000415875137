.custom-container {
  width: 90%;
  margin: auto;
}
.custom-container section {
  width: 90%;
  height: 100vh;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.custom-container section.custom-hero h1 {
  width: 100%;
  text-align: center;
  letter-spacing: -0.05em;
  line-height: 90%;
}
.custom-container section.custom-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.custom-container section.custom-card.custom-scroll {
  position: relative;
}
.custom-container section.custom-card .custom-img {
  width: 40%;
  height: 80%;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  padding: 1rem;
  background-color: #333;
  color: #FFFFFF;
}
.custom-container section.custom-card .custom-img h3 {
  z-index: 2;
}
.custom-container section.custom-card .custom-img .custom-pitch-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.custom-container section.custom-card .custom-img .custom-pitch-title p {
  width: 80%;
  margin: auto;
}
.custom-container section.custom-card .custom-img .custom-pitch-title .svg-back {
  z-index: 1;
}
.custom-container section.custom-card .custom-img img {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.custom-container section.custom-footer {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-container section.custom-footer h1 {
  color: red;
  font-size: 2em;
  text-align: center;
  letter-spacing: -0.05em;
}

@media (max-width: 480px) {
  .custom-container section {
    height: 50vh;
  }
  .custom-container .custom-pitch-title h3 {
    font-size: 1.4rem;
    margin-left: 0;
  }
}/*# sourceMappingURL=company-pitch.css.map */
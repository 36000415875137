.landing-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #5733FF;
  flex-wrap: wrap;
  gap: 0.25rem;
  position: relative;
}
.landing-page .mouse-container {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 25%;
  right: 25%;
}
.landing-page .enter-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  z-index: 1001;
  position: absolute;
  top: 20%;
  left: 48.5%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  font-weight: bold;
}
.landing-page .enter-button span {
  animation: buttonAnimation 2s infinite;
}
.landing-page .enter-button:hover {
  color: #020202;
  outline: 1px solid #020202;
  font-size: bold;
}
.landing-page .enter-button:hover span {
  animation: none;
}

.landing-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: bold;
  color: #FFFFFF;
  z-index: 1000;
}

.box {
  width: 12rem;
  height: 12rem;
  margin: 0.5rem;
  background-color: rgba(83, 83, 246, 0.3882352941);
  display: flex;
  justify-content: center;
  align-items: center;
  color: darkblue;
  font-size: 1.5rem;
  font-weight: bold;
}

.even {
  border-radius: 0.5rem;
  background-color: rgba(114, 114, 252, 0.3882352941);
  box-shadow: 15px 15px 15px rgb(58, 29, 153);
}

.text {
  font-size: 1.5rem;
  margin-top: 1.5rem;
}

@keyframes buttonAnimation {
  0% {
    color: #020202; /* Start with white background */
  }
  50% {
    color: #FFFFFF; /* Change background color to red */
  }
  100% {
    color: #020202; /* Return to white background */
  }
}/*# sourceMappingURL=landing_page.css.map */
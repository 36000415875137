@import 'settings';


.explore-more-container {
    position: absolute; /* Use absolute positioning to center relative to parent */
    top: 50%; /* Center vertically */
    left: 70%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Translate to achieve perfect centering */
    z-index: 1001; /* Adjust z-index as needed */
    opacity: 1; /* Ensure opacity is set as needed */
    
   

    button{
        font-size: $h4-font-size;
        padding: $margin-small $margin-large;
    }
}


@import 'settings';

$cube-container-width: 100vw;
$cube-container-height: 100vh;
$cube-size: 200px;
$cube-border-color: $primary-color;
// $cube-background-color: $secondary-color-fade;
$cube-border-radius: 10%;
$cube-border: 1px solid $cube-border-color;

$cylinder-size: 100px; // Diameter of the circle
$cylinder-height: 100px; // Height of the cylinder
$cylinder-color: rgba(255, 0, 0, 0.5); // Semi-transparent red color for the circle
$side-color: rgba(0, 0, 255, 0.5); // Semi-transparent blue color for the sides

.cube-container {
  width: $cube-container-width;
  height: $cube-container-height;
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px; // Perspective for 3D effect
  background-color: $secondary-color;
}
.intro-text-cube{
  color: $primary-color;
  font-size: 48px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-weight: bold;

}
.cube {
  width: $cube-size;
  height: $cube-size;
  position: relative;
  transform-style: preserve-3d;
  animation: spin 5s linear infinite; // Animation for continuous rotation
}

.cube-image {
  width: 100%;
  height: 100%;
  object-fit: cover; // Ensure the image fills the entire div
  transform: none !important; // Override any inherited transformations
}

.cube div {
  position: absolute;
  width: $cube-size;
  height: $cube-size;
  border: 1px solid $cube-border-color;
  // background-color: $cube-background-color;
  border-radius: $cube-border-radius;
  border: $cube-border;
}

.cube .front {
  transform: translateZ(100px);
}

.cube .back {
  transform: rotateY(180deg) translateZ(100px);
}

.cube .right {
  transform: rotateY(-90deg) translateZ(100px);
}

.cube .left {
  transform: rotateY(90deg) translateZ(100px);
}

.cube .top {
  transform: rotateX(90deg) translateZ(100px);
}

.cube .bottom {
  transform: rotateX(-90deg) translateZ(100px);
}

.cylinder {
  position: absolute;
  top: 50%;
  left: 50%;
  width: $cylinder-size;
  height: $cylinder-height;
  transform: translate(-50%, -50%) rotateX(90deg); // Rotate the cylinder to be parallel to the front face of the cube
}

.circle {
  width: $cylinder-size;
  height: $cylinder-size;
  background-color: $cylinder-color;
  border-radius: 50%;
  position: absolute;
  top: 0; // Position the circle at the top face of the cylinder
}

.side {
  width: $cylinder-size;
  height: $cylinder-height;
  background-color: $side-color;
  position: absolute;
}

.side:nth-child(1) {
  transform: rotateY(0deg) translateZ(calc($cylinder-size / 2)); // Position the side at the front face of the cylinder
}

.side:nth-child(2) {
  transform: rotateY(90deg) translateZ($cylinder-size / 2); // Position the side at the right face of the cylinder
}

.side:nth-child(3) {
  transform: rotateY(180deg) translateZ($cylinder-size / 2); // Position the side at the back face of the cylinder
}

.side:nth-child(4) {
  transform: rotateY(-90deg) translateZ($cylinder-size / 2); // Position the side at the left face of the cylinder
}

@keyframes spin {
  from {
    transform: rotateY(0deg) rotateX(0deg);
  }
  to {
    transform: rotateY(360deg) rotateX(-360deg);
  }
}

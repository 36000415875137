.custom-wave {
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-wave svg {
  position: relative;
  display: block;
  width: calc(187% + 1.3px);
}/*# sourceMappingURL=custom-wave.css.map */
.navigation-language {
  margin-top: 16px;
  position: absolute;
  top: 0;
  right: 5%;
  width: 20vw;
  height: 60px;
  display: flex;
  justify-content: end;
  align-items: center;
} /*# sourceMappingURL=navigation_language.css.map */
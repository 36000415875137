.sub-container .motto-products h2 {
  color: #FFFFFF;
  text-transform: uppercase;
}
.sub-container .search-container {
  width: 50%;
  margin: auto;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  position: relative;
}
.sub-container .search-container .loader {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  width: 15%;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: #5733FF;
  border-radius: 25%;
}
.sub-container .search-container .search-input {
  width: 100%;
  font-size: 0.8rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 3rem;
  border-radius: 0.25rem;
  text-align: left;
  text-indent: 0.5rem;
  font-family: "Montserrat", sans-serif;
  margin-right: 0.5rem;
  border: 1px solid rgba(114, 114, 252, 0.3882352941);
}
.sub-container .search-container .search-button {
  background-color: rgba(114, 114, 252, 0.3882352941);
  color: #FFFFFF;
  border: 1px solid rgba(114, 114, 252, 0.3882352941);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  margin-right: 0.5rem;
}
.sub-container .search-container .search-button:hover {
  background-color: #020202;
  color: #FFD9E8;
  cursor: pointer;
}
.sub-container .products-display {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border: 2px solid rgba(114, 114, 252, 0.3882352941);
  margin: auto;
  background-color: #eee;
  color: #020202;
  padding: 3rem 2rem;
  gap: 0.5rem;
}
.sub-container .products-display a {
  text-decoration: none;
  color: inherit;
  display: block;
  width: calc(30% - 0.5rem);
}
.sub-container .products-display a:hover {
  text-decoration: none;
  color: inherit;
}
.sub-container .product-item {
  width: 100%;
  height: 18rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.25rem;
  background-color: #FFFFFF;
  flex-grow: 1;
  border-radius: 0.5rem;
}
.sub-container .product-item h4 {
  text-align: center;
  font-size: 1.4rem;
}
.sub-container .product-item p {
  display: block;
  text-align: center;
  font-size: 0.8rem;
}
.sub-container .product-item h4, .sub-container .product-item p {
  word-break: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto; /* For Safari and Safari on iOS */
  hyphens: auto;
}
.sub-container .product-item img {
  display: block;
  max-width: 50%;
  max-height: 50%;
  -o-object-fit: contain;
     object-fit: contain;
  transition: transform 0.3s ease-in-out;
}
.sub-container .product-item:hover img {
  transform: scale(1.3); /* Adjust the scale factor as needed */
  cursor: pointer;
  text-decoration: none;
}
.sub-container .product-item .price-container {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sub-container .product-item .price-container > p:nth-child(2) {
  text-decoration: line-through;
  opacity: 0.8;
}
.sub-container .button-cta-products {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
  color: #020202;
  font-size: 1rem;
  overflow: hidden;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.sub-container .button-cta-products button {
  width: 100%;
  height: 100%;
  background-color: #FFD9E8;
  cursor: pointer;
}
.sub-container .button-cta-products button:hover {
  background-color: #020202;
  color: #FFD9E8;
  font-weight: bold;
}

@media (max-width: 480px) {
  .button-cta-products {
    min-width: 50%;
    margin: auto;
    max-height: 5vh;
  }
}/*# sourceMappingURL=product_page.css.map */
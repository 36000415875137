@import 'settings';

.admin-content-form{

    form{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin: auto;
        padding: $margin-big auto
    }

    input, textarea{
        width: 40%;
    }

    table {
        width: 80%;
        margin: $margin-big auto;
    
    }
    
    thead {
        font-size: $p-font-size-small;
        font-weight: bold;
    
        th{
            font-size: $p-font-size-tiny;
        }
    }
    
    tbody {
        font-size: $p-font-size-small;
    
        button{
            font-size: $p-font-size-tiny;
            background-color: $secondary-color-fade-dark;
            color: $secondary-color-fade-light;
            padding: $margin-bento;
    
            &:hover{
                background-color: red;
            }
        }
    }

}

.wave-svg-morph {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  width: 100%;
  height: auto; /* Adjust the height accordingly */
}
.wave-svg-morph svg {
  display: block; /* Remove inline SVG whitespace */
  width: 100%; /* Make the SVG responsive */
  height: auto; /* Maintain aspect ratio */
}/*# sourceMappingURL=svg-morph.css.map */
.cta-about {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin: auto;
}
.cta-about .cta-about-sub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.cta-about .cta-about-sub p {
  width: 70%;
  line-height: 1.5;
}
.cta-about .cta-about-sub img {
  max-width: 30%;
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
}
.cta-about button {
  background-color: #5733FF;
  color: #FFD9E8;
}/*# sourceMappingURL=cta-about.css.map */
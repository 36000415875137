.testimonies-wrapper {
  width: 90%;
  max-width: 1536px;
  margin-inline: auto;
  position: relative;
  height: 100px;
  margin-top: 5rem;
  overflow: hidden;
  height: 70vh;
  -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0) 20%, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0));
          mask-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0, 0, 0) 20%, rgb(0, 0, 0) 80%, rgba(0, 0, 0, 0));
}

@keyframes scrollLeft {
  to {
    left: -200px;
  }
}
.testimony-item {
  width: 25%;
  height: 80%;
  flex-shrink: 0;
  margin-right: 2rem;
  color: #FFFFFF;
  padding: 0.125rem 1rem;
  border-bottom: 1px solid #5733FF;
  border-right: 1px solid #5733FF;
  border-left: 1px solid #5733FF;
  border-radius: 0.5rem;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: center;
  animation-name: scrollLeft;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.testimony-item p {
  max-width: 90%;
  margin-bottom: 0.5rem;
}
.testimony-item .quote-icon {
  font-size: 3rem;
  color: #5733FF;
  margin: 0.125rem auto;
  z-index: -1;
}
.testimony-item .left {
  position: absolute;
  left: 5%;
  top: 10%;
  transform: rotate(30deg);
}
.testimony-item .right {
  position: absolute;
  right: 10%;
  bottom: 10%;
  transform: rotate(30deg);
}
.testimony-item span {
  font-size: 0.8rem;
}

@media (max-width: 480px) {
  .testimonies-container {
    height: 50dvh;
  }
  .testimonies-wrapper {
    height: 50dvh;
  }
  .testimony-item {
    min-width: 30%;
  }
  .loader-testimony {
    min-width: 20%;
    max-height: 50%;
  }
}/*# sourceMappingURL=testimonies.css.map */
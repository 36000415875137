@import 'settings';

.pitch-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: $margin-big;
    gap: $margin-big;
 

    .pitch{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin: auto;
        padding: $margin-big;
        margin-bottom: $margin-medium;
        gap: $margin-medium;
        position: relative;
        text-align: center;
        height: 250px;
        // border: 1px solid red;

        // &::after {
        //     content: "";
        //     position: absolute;
        //     bottom: 0;
        //     left: 35%; /* Adjust to cover 50% of the container width */
        //     width: 30%; /* Cover 50% of the container width */
        //     height: 5px; /* Border height */
        //     background-color: $primary-color;
        //           }
      
       

        >div{
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: $margin-small;

            img{
                
                width: 60%;
                height: auto;
                object-fit: contain;
                outline: 1px solid $secondary-color;
                border-radius: $margin-big;
            }

            p{
                text-transform: capitalize;
            }
           
        }
        
        .pitch-title{
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            // height: 100px;
          

        }
        
        // border: 1px solid red;
    }
}
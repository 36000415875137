@import 'settings';

.cart-details{
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $margin-small;
    flex-wrap: wrap;

     
    button {
        width: 100%;     
        height: 100%; 
        background-color: $accent-color;
        cursor: pointer;
        padding:  $margin-small;
        
          
        &:hover {
          background-color: $secondary-color-black;
          color: $accent-color;
          font-weight: bold;
        }
      }

    .button-cta-cart-container{
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: $secondary-color-black;    
        font-size: $p-font-size;
        border-radius: $margin-small;
        overflow: hidden;
        
       
       
        gap: $margin-small;
      
      }

    .cart-items-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: $margin-large;
        border: 2px solid $primary-color-fade-light;

        img{
            display: block;
            width: 50px;
            height: 50px;
        }

        div{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: $margin-small;
            border: 1px solid $primary-color-fade-light;

            p{  
                font-size: $p-font-size;
            }

            div{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: $margin-small;
            }
        }
    }
}